import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import useCardType from '../cardType';

export const ExperimentContext = createContext({});

const ExperimentProvider = ({ children }) => {
    const { isCardTypeTest, cardTypeTestVariant } = useCardType();
    const experiments = {
        isCardTypeTest,
        cardTypeTestVariant
    };
    return <ExperimentContext.Provider value={experiments}>{children}</ExperimentContext.Provider>;
};

export const useExperiments = () => useContext(ExperimentContext);

export default ExperimentProvider;

ExperimentProvider.propTypes = {
    children: PropTypes.node.isRequired
};
