import { css, styled } from 'styled-components';

const TitleSection = styled.div.attrs({
    'data-qa-id': 'page-title-section'
})`
    ${({ theme }) => css`
        background-color: ${theme.colors.capitalOne.blue750};
        padding: ${theme.space.lg} 0;
        text-align: center;

        ${theme.breakpoints.mq.tabletMin} {
            padding: 7.5rem 0;
        }
        ${theme.components.TitleSection}
    `}
`;

TitleSection.displayName = 'TitleSection';

export default TitleSection;
