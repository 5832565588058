import { useState } from 'react';

import config from '@cof/ukweb-config/lib/clientConfig';

import { useAxios, useAxiosCallback } from '../hooks/useAxios';
import getConfig from './config';

const ARTIFICIAL_DELAY = 1500;

export const useFormData = (params, formData) => useAxios({ ...getConfig('form'), params }, !formData);

export const useFormSubmit = () => {
    const [delaying, setDelaying] = useState(false);
    const [submitForm, submitting, ...rest] = useAxiosCallback((data) => {
        setDelaying(true);
        setTimeout(() => setDelaying(false), ARTIFICIAL_DELAY);
        const { downstreamVersionIsEnabled } = config.get('useDownstreamVersion') || {};

        if (downstreamVersionIsEnabled) {
            return { ...getConfig('quotation'), withCredentials: true, data, method: 'post' };
        }

        return { ...getConfig('quotation'), data, method: 'post' };
    });
    return [submitForm, submitting || delaying, ...rest];
};

export const useAddressLookupData = (sessionId) =>
    useAxiosCallback((data) => {
        const headers = { ...getConfig('addressLookup').headers, ...{ 'Session-Id': sessionId } };
        return { ...getConfig('addressLookup'), headers, data, method: 'post' };
    });

export const useStageData = (sessionId) =>
    useAxiosCallback((data) => {
        const headers = { ...getConfig('stage').headers, ...{ 'Session-Id': sessionId } };
        return { ...getConfig('stage'), headers, data, method: 'post' };
    });

export const shouldShowTechError = (brand) => {
    const { brands } = config.get('showTechError');
    return brands.map((x) => x.toLowerCase()).includes(brand.toLowerCase());
};
