import React from 'react';
import PropTypes from 'prop-types';
import { redirect } from 'react-router';

import { useBrand } from '../../../BrandContext';

import Spinner from '../../../pages/Spinner';
import partners from '../../../partners';
import { useAggregatorConsumerName } from '../../../utilities/aggregatorConsumerNameProvider';
import { decisions, pCRValue, routes } from '../../../utilities/constants';
import pushToDataLayer, { EVENTS } from '../../../utilities/dataLayer';
import { addNewRelicPageAction } from '../../../utilities/newRelic';
import { isAggregatorConsumer, isHosted } from '../../../utilities/utils';
import getResultPage from './getResult';

const addUrlParamConditionally = (paramName, paramValue, url) => {
    if (paramValue) url.searchParams.set(paramName, paramValue);
};

const setProductCode = (ccsCodeQuotation, brand, aggregatorConsumerName, urlWithParams) => {
    if (ccsCodeQuotation && (brand !== partners.DEFAULT || isAggregatorConsumer(aggregatorConsumerName))) {
        urlWithParams.searchParams.set('p', ccsCodeQuotation);
    }
};
const agreementUrlBuilder = (
    agreementUrl,
    sParameterQuotation,
    ccsCodeQuotation,
    brand,
    aggregatorConsumerName,
    pcr,
    channel,
    campaign,
    vendorCode
) => {
    const urlWithParams = new URL(agreementUrl);

    setProductCode(ccsCodeQuotation, brand, aggregatorConsumerName, urlWithParams);
    if (!isHosted(brand) || isAggregatorConsumer(aggregatorConsumerName)) {
        addUrlParamConditionally('pcr', pcr, urlWithParams);
    } else if (brand !== partners.DEFAULT) {
        urlWithParams.searchParams.set('pcr', pCRValue);
    }
    addUrlParamConditionally('s', sParameterQuotation, urlWithParams);
    addUrlParamConditionally('channel', channel, urlWithParams);
    addUrlParamConditionally('campaign', campaign, urlWithParams);
    addUrlParamConditionally('vendorCode', vendorCode, urlWithParams);
    return urlWithParams;
};

/* eslint-disable-next-line complexity */ // This is because there are 6 decline scenarios so refactoring to meet complexity makes function harder to read
const isDecline = (reason) =>
    reason === decisions.INFORMATION_MISMATCH ||
    reason === decisions.SCORE_DECLINE ||
    reason === decisions.CREDIT_BUREAU_DECLINE ||
    reason === decisions.AFFORDABILITY_DECLINE ||
    reason === decisions.ALREADY_APPLIED_DECLINE;

const Result = ({ responseData = {}, submitting = false, pcr = '', campaign = '', channel = '', vendorCode = '' }) => {
    const { reason, userData } = responseData;
    const brand = useBrand();
    const [aggregatorConsumerName] = useAggregatorConsumerName();

    React.useEffect(() => {
        if (reason) addNewRelicPageAction('Result', { decision: reason });
    }, [reason]);

    React.useEffect(() => {
        if (reason === decisions.MATCH_AND_OFFER) {
            pushToDataLayer({
                event: EVENTS.SUBMISSION_RESULT,
                result: 'approved'
            });
            const { ccsCode: ccsCodeQuotation, sParameter: sParameterQuotation, agreementUrl } = responseData;

            window.location.assign(
                agreementUrlBuilder(
                    agreementUrl,
                    sParameterQuotation,
                    ccsCodeQuotation,
                    brand,
                    aggregatorConsumerName,
                    pcr,
                    channel,
                    campaign,
                    vendorCode
                ).href
            );
        } else if (isDecline(reason)) {
            pushToDataLayer({
                event: EVENTS.SUBMISSION_RESULT,
                result: 'declined'
            });
        } else if (reason === decisions.ERROR) {
            pushToDataLayer({
                event: EVENTS.SUBMISSION_RESULT,
                result: 'error'
            });
        }
    }, [submitting, brand, channel, pcr, reason, responseData, vendorCode, aggregatorConsumerName, campaign]);

    if (submitting || reason === decisions.MATCH_AND_OFFER) {
        return <Spinner />;
    }

    if (!submitting && !reason) {
        return redirect(routes.baseWithQuery(window.location.search));
    }

    return getResultPage(reason, userData);
};

Result.propTypes = {
    responseData: PropTypes.shape({
        reason: PropTypes.string,
        userData: PropTypes.object,
        token: PropTypes.string,
        ccsCode: PropTypes.string,
        sParameter: PropTypes.string,
        agreementUrl: PropTypes.string
    }),
    submitting: PropTypes.bool,
    pcr: PropTypes.string,
    campaign: PropTypes.string,
    vendorCode: PropTypes.string,
    channel: PropTypes.string
};

export default Result;
