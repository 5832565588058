import PropTypes from 'prop-types';

import { Button, Link, Paragraph } from '@cof/plastic-components';

import BodySection from '../../components/BodySection';
import Header from '../../components/Header';
import Main from '../../components/Main';
import TitleSection, { TitleSectionHeading } from '../../components/TitleSection';
import { withText } from '../../text/textStore';
import useBackground from '../../utilities/useBackground';

const AlreadyAppliedDecline = ({ Text, Text: text, TemplateText, JsxText, firstName }) => {
    const background = useBackground('alreadyApplied');

    return (
        <>
            <Header />
            <Main bg={background} data-qa-id="already-applied-decline-page">
                <TitleSection>
                    <TitleSectionHeading>
                        <TemplateText path="Title" params={{ NAME: firstName }} />
                    </TitleSectionHeading>
                </TitleSection>
                <BodySection textAlign="center" data-qa-id="body">
                    <Paragraph data-qa-id="page-paragraph1" whiteSpace="pre-wrap">
                        <Text path="Paragraph1" />
                    </Paragraph>
                    <Paragraph data-qa-id="page-paragraph2">
                        <JsxText
                            path="Paragraph2"
                            params={{
                                CONTACT: (
                                    <Link href={text({ path: 'ContactUsLink' })}>
                                        <Text path="ContactUs" />
                                    </Link>
                                )
                            }}
                        />
                    </Paragraph>
                    <Button mt="md" as="a" data-qa-id="homepage-link" href={text({ path: 'HomePageLink' })}>
                        <Text path="HomePage" />
                    </Button>
                </BodySection>
            </Main>
        </>
    );
};

AlreadyAppliedDecline.propTypes = {
    Text: PropTypes.func.isRequired,
    TemplateText: PropTypes.func.isRequired,
    JsxText: PropTypes.func.isRequired,
    firstName: PropTypes.string.isRequired
};

const AlreadyAppliedDeclineWithText = withText('AlreadyAppliedDecline')(AlreadyAppliedDecline);
AlreadyAppliedDeclineWithText.displayName = 'AlreadyAppliedDecline';

export default AlreadyAppliedDeclineWithText;
