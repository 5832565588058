import { css } from 'styled-components';

export const StyledLink = ({ theme }) => css`
    font-weight: ${theme.fontWeights.semiBold};
`;

export const StageHeading = ({ theme }) => css`
    color: ${theme.colors.global.white};
    background-color: #27314a;
`;

export const LoadingSpinnerBranded = ({ theme }) => css`
    color: ${theme.colors.global.white};
`;
