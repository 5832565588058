import PropTypes from 'prop-types';

import { Box, Flow, FlowPoint, Heading, Link, Paragraph } from '@cof/plastic-components';

import { withText } from '../../text/textStore';
import BodySection from '../BodySection';

const CheckYourCreditFileSection = ({ Text, Text: text, JsxText }) => (
    <Box bg="global.grey50" data-qa-id="credit-file">
        <BodySection textAlign="center" data-qa-id="credit-file-inner">
            <Heading level="2">
                <Text path="Heading" />
            </Heading>
            <Paragraph data-qa-id="body1">
                <Text path="Body1" />
            </Paragraph>
            <Flow data-qa-id="flow" variant="dark" my="md" px={0}>
                {[1, 2, 3, 4].map((point) => (
                    <FlowPoint key={point}>{text({ path: `Points.Point${point}` })}</FlowPoint>
                ))}
            </Flow>
            <Paragraph data-qa-id="body2">
                <JsxText
                    path="Body2.Text"
                    params={{
                        EQUIFAX: (
                            <Link external href={text({ path: 'Body2.Equifax.Target' })}>
                                <Text path="Body2.Equifax.Text" />
                            </Link>
                        ),
                        TRANSUNION: (
                            <Link external href={text({ path: 'Body2.Transunion.Target' })}>
                                <Text path="Body2.Transunion.Text" />
                            </Link>
                        ),
                        EXPERIAN: (
                            <Link external href={text({ path: 'Body2.Experian.Target' })}>
                                <Text path="Body2.Experian.Text" />
                            </Link>
                        )
                    }}
                />
            </Paragraph>
        </BodySection>
    </Box>
);

CheckYourCreditFileSection.propTypes = {
    Text: PropTypes.func.isRequired,
    JsxText: PropTypes.func.isRequired
};

const WrappedCheckYourCreditFileSection = withText('CheckYourCreditFile')(CheckYourCreditFileSection);

WrappedCheckYourCreditFileSection.displayName = 'CheckYourCreditFileSection';

export default WrappedCheckYourCreditFileSection;
