import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { useBrand } from '../../BrandContext';
import { withText } from '../../text/textStore';

const DocumentHead = ({ Text: text }) => {
    const brand = useBrand();
    return (
        <Helmet>
            <link rel="icon" href={`/${brand}_favicon.ico`} />
            <title>{text({ path: 'Title' })}</title>
            <meta name="Description" content={text({ path: 'Description' })} />
        </Helmet>
    );
};

DocumentHead.propTypes = {
    Text: PropTypes.func.isRequired
};

export default withText('DocumentHead')(DocumentHead);
