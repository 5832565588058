import { useContext, useEffect } from 'react';

import ErrorContext from '../errorContext';

const useTechnicalError = (error) => {
    const { setError } = useContext(ErrorContext);
    useEffect(() => {
        if (error) {
            setError();
        }
    }, [error, setError]);
};

export default useTechnicalError;
