import PropTypes from 'prop-types';
import { Box, Flex, Heading, Paragraph, Link } from '@cof/plastic-components';
import { styled } from 'styled-components';
import homeLoungeImage from './home-lounge.webp';

import { withText } from '../../text/textStore';
import MoneyHelperLogo from './MoneyHelperLogo';

const StyledImage = styled.img`
    width: 100%;
    border-radius: 33px;
`;

const MoneyHelperBanner = ({ Text, Text: text, JsxText }) => (
    <Flex
        data-qa-id="money-helper-banner"
        justifyContent="center"
        py={['0', 'md']}
        flexDirection={['column', 'row', 'row']}
    >
        <Box maxWidth={['100%', '60%', '50%', '50%']} pr={['0', 'xs', 'lg']} alignContent="center">
            <Flex justifyContent={['center', 'left']}>
                <MoneyHelperLogo maxWidth={['72px', '72px', '94px']} my={['xs', 'xs', 'sm']} />
            </Flex>
            <Heading level="3" textAlign={['center', 'left']} data-qa-id="money-helper-heading">
                <Text path="Heading" />
            </Heading>
            <Paragraph data-qa-id="money-helper-text1" pb="md">
                <Text path="Body1" />
            </Paragraph>
            <Paragraph data-qa-id="money-helper-text2" pb="md">
                <JsxText
                    path="Body2"
                    params={{
                        MONEY_HELPER_GUIDE: (
                            <Link external href={text({ path: 'GuideLink' })}>
                                <Text path="LinkText" />
                            </Link>
                        )
                    }}
                />
            </Paragraph>
        </Box>
        <Box alignContent="center" display="flex" justifyContent="center">
            <Box width={['80%', '100%']} pb="md" alignContent="center">
                <StyledImage src={homeLoungeImage} alt="home lounge image" data-qa-id="home-lounge-image" />
            </Box>
        </Box>
    </Flex>
);

MoneyHelperBanner.propTypes = {
    Text: PropTypes.func.isRequired,
    JsxText: PropTypes.func.isRequired
};

const WrappedMoneyHelperBanner = withText('MoneyHelperBanner')(MoneyHelperBanner);

WrappedMoneyHelperBanner.displayName = 'MoneyHelperBanner';

export default WrappedMoneyHelperBanner;
