import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Heading as BaseHeading } from '@cof/plastic-components';
import { styled } from 'styled-components';

import { useLandmarkRefsContext } from '../LandmarkRefsProvider';

export const Heading = styled(BaseHeading)`
    outline: none;
`;
Heading.displayName = 'Heading';

const HeadingLevelOne = (props) => {
    const ref = useRef();
    const { setPageHeadingRef } = useLandmarkRefsContext();

    useEffect(() => {
        if (setPageHeadingRef) {
            setPageHeadingRef(ref);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return <Heading data-qa-id="page-title" ref={ref} tabIndex="-1" {...props} />;
};

HeadingLevelOne.propTypes = {
    children: PropTypes.node.isRequired
};

export default HeadingLevelOne;
