import { CaretRightIcon, ListItem as PlasticListItem } from '@cof/plastic-components';
import { css, styled } from 'styled-components';

export const HeroListIcon = styled(CaretRightIcon)`
    vertical-align: middle;
    ${({ theme }) => css`
        color: ${theme.colors.capitalOne.teal450};
        ${theme.components.heroListIcon}
    `}
`;
HeroListIcon.displayName = 'HeroListIcon';

export const IconlessListItem = styled.li`
    ${({ theme }) => css`
        margin: ${theme.space.xs} 0;
        position: relative;
        z-index: 1;

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }

        ${theme.breakpoints.mq.tabletMin} {
            margin: ${theme.space.sm} 0;
        }
    `}
`;
IconlessListItem.displayName = 'IconlessListItem';

export const ListItem = styled(PlasticListItem).attrs(() => ({
    Icon: () => <HeroListIcon />
}))`
    ${({ theme }) => css`
        margin: ${theme.space.sm} 0;
        ${theme.breakpoints.mq.tabletMin} {
            /* stylelint-disable-next-line selector-max-type  */
            > div {
                align-items: center;
            }
        }

        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
    `}
`;
ListItem.displayName = 'ListItem';
