import PropTypes from 'prop-types';

import { Heading as BaseHeading } from '@cof/plastic-components';
import { css, styled } from 'styled-components';

import Header from '../../components/Header';
import LoadingSpinner from '../../components/LoadingSpinner';
import Main from '../../components/Main';
import TitleSection from '../../components/TitleSection';
import { withText } from '../../text/textStore';
import useBackground from '../../utilities/useBackground';
import useIcon from '../../utilities/useIcon';

export const Heading = styled(BaseHeading).attrs(() => ({ level: '1', size: 'small', mt: 'md' }))`
    ${({ theme }) => css`
        color: ${theme.colors.global.white};
        ${theme.components.SpinnerHeading}
    `}
`;

Heading.displayName = 'Heading';

const TitleSectionStyled = styled(TitleSection)`
    height: 100%;
`;
TitleSectionStyled.displayName = 'TitleSectionStyled';

const Spinner = ({ Text }) => {
    const background = useBackground('spinner');
    const icon = useIcon('spinner', true);

    return (
        <>
            <Header />
            <Main bg={background} data-qa-id="spinner">
                <TitleSectionStyled>
                    <LoadingSpinner Icon={icon} features={{ resultSpinner: true }}>
                        <Heading data-qa-id="page-title">
                            <Text path="Title" />
                        </Heading>
                    </LoadingSpinner>
                </TitleSectionStyled>
            </Main>
        </>
    );
};

Spinner.propTypes = {
    Text: PropTypes.func.isRequired
};

const WrappedSpinner = withText('Spinner')(Spinner);

WrappedSpinner.displayName = 'Spinner';

export default WrappedSpinner;
