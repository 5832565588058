import '@cof/plastic-components/fonts.css';

import ReactDOM from 'react-dom/client';

import App from './App';

import '@csstools/normalize.css'; // replace CRA built in normalize with equivalent package

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);
