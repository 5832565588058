import { useBrand } from '../../BrandContext';
import partners from '../../partners';

const useQueryParams = () => {
    const brand = useBrand();

    const getSearchParameter = function (parameter) {
        const query = new URLSearchParams(window.location.search);
        return query.get(parameter) || undefined; // undefined is added because query.get returns null if not found
    };

    const getHashedParameter = function (parameter) {
        if (window.location.hash !== undefined) {
            const hashParams = new URLSearchParams(window.location.hash.substring(1));
            return hashParams.get(parameter);
        }
        return undefined;
    };

    const productId = getSearchParameter('p') || (brand === partners.LUMA && 'CCS83') || undefined;

    const validVendorCode = (vendorCode) => (/^[0-9A-Za-z]{3,}$/.test(vendorCode) ? vendorCode : undefined);

    const sParameter = getSearchParameter('s');
    const sVendorCode = sParameter?.substring(8, 11)?.replace('x', '');

    const oemc = validVendorCode(getSearchParameter('o'));

    const vendorCode = validVendorCode(getSearchParameter('v')) || validVendorCode(sVendorCode);

    return {
        sParameter,
        quotation: getSearchParameter('quotation'),
        productId,
        vendorCode,
        oemc,
        pcr: getSearchParameter('pcr'),
        campaign: getSearchParameter('campaign'),
        channel: getSearchParameter('channel'),
        btNotOffered: !!getSearchParameter('btnotoffered'),
        firstName: getHashedParameter('firstName'),
        lastName: getHashedParameter('lastName')
    };
};

export default useQueryParams;
