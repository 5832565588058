import { css, styled } from 'styled-components';

import HeadingLevelOne from '../HeadingLevelOne';

const TitleSectionHeading = styled(HeadingLevelOne)`
    ${({ theme }) => css`
        max-width: 90%;
        margin: auto;
        color: ${theme.colors.global.white};
        ${theme.components.BodyWidth}
        ${theme.components.TitleSectionHeading}
    `}
`;

export default TitleSectionHeading;
