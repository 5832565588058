import { css } from 'styled-components';

export default css`
    /* stylelint-disable-next-line selector-max-universal */
    * {
        box-sizing: border-box;
    }

    html {
        font-size: 62.5%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-size-adjust: 100%;
        height: 100%;
    }

    body {
        margin: 0;
        min-height: 100%;
        font-size: 1.6rem;
        display: flex;
        font-family: ${({ theme }) => theme.fonts.body};
    }

    main {
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;
    }

    /* stylelint-disable-next-line selector-max-specificity, selector-max-id */
    #root {
        display: flex;
        flex-direction: column;
        flex: 1 1 0%;
    }
`;
