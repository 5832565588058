/**
 * An object of character codes.
 */
import partners from '../partners';

export const characters = {
    tab: 9,
    enter: 13,
    escape: 27,
    spacebar: 32,
    end: 35,
    home: 36,
    left: 37,
    up: 38,
    right: 39,
    down: 40
};

export const numericInputPattern = '[0-9]*';

export const autoCompleteMapping = {
    'title': 'honorific-prefix',
    'first-name': 'given-name',
    'last-name': 'family-name',
    'email-address': 'email',
    'date-of-birth': 'bday',
    'mobile-number': 'tel'
};

export const routes = {
    base: '/',
    baseWithQuery: (query) => `/${query}`,
    result: '/result',
    nextStage: (stageId, query) => `/${stageId}${query}`
};

export const decisions = {
    MATCH_AND_OFFER: 'MATCH_AND_OFFER',
    ERROR: 'ERROR',
    INFORMATION_MISMATCH: 'INFORMATION_MISMATCH',
    SCORE_DECLINE: 'SCORE_DECLINE',
    CREDIT_BUREAU_DECLINE: 'CREDIT_BUREAU_DECLINE',
    AFFORDABILITY_DECLINE: 'AFFORDABILITY_DECLINE',
    ALREADY_APPLIED_DECLINE: 'ALREADY_APPLIED_DECLINE'
};

export const CONSUMER_NAMES = {
    [partners.DEFAULT]: 'QCHK',
    [partners.ASOS]: 'ASOS',
    [partners.LUMA]: 'LUMA',
    [partners.VERY]: 'VERY',
    [partners.LITTLEWOODS]: 'LTWD',
    [partners.OCEAN]: 'OCEAN',
    [partners.THINKMONEY]: 'THKM',
    [partners.POSTOFFICE]: 'POST'
};

export const BRANDS = ['capitalOne', 'luma', 'littlewoods', 'ocean', 'postoffice', 'thinkmoney', 'very', 'asos'];

export const HOSTED_PARTNERS = [partners.DEFAULT, partners.LUMA, partners.VERY, partners.LITTLEWOODS, partners.ASOS];

export const API_PARTNERS = [partners.POSTOFFICE, partners.OCEAN, partners.THINKMONEY];

export const AGGREGATOR_WITH_LOGO = ['MSM', 'CLRS', 'TOTM', 'CTM', 'USWC', 'MCU', 'EXPR', 'CK'];

export const AGGREGATOR_CONSUMER_NAMES = ['MSE', 'CCC', ...AGGREGATOR_WITH_LOGO];

export const INSTANT_ISSUANCE_BRANDS = [partners.ASOS, partners.LITTLEWOODS, partners.VERY];

export const journeys = {
    BALANCE_TRANSFER: 'balanceTransfer',
    STANDARD: 'standard'
};

// pCR is partnerAppId, which is required parameter to load On-Prem application form for partners
export const pCRValue = '9652602104';

export const dateSeparator = ' ';
export const dateOfBirthPlaceholder = `DD${dateSeparator}MM${dateSeparator}YYYY`;
export const dateOfBirthPattern = /^\d{0,2}((?:\d{2})\s\d{0,2}((?:\d{2})\s\d{0,4})?)?$/;

export const idSeparator = ':';

export const APR = {
    low: 34.9,
    high: 39.9
};

export const channelAPRs = {
    GOG: APR.high,
    BIG: APR.high,
    FB5: APR.high,
    GDD: APR.high,
    GD2: APR.high,
    MDN: APR.high,
    YUD: APR.high,
    YU1: APR.high,
    PAS: APR.high,
    default: APR.low
};
