import { Box } from '@cof/plastic-components';
import cssSystem from '@styled-system/css';
import { css, styled } from 'styled-components';

const BodySection = styled(Box)`
    ${({ theme }) => css`
        ${cssSystem({
            maxWidth: '90%',
            py: ['lg', null, 'xl'],
            mx: 'auto'
        })}

        ${theme.components.BodyWidth}
    `}
`;

BodySection.displayName = 'BodySection';

export default BodySection;
