import { createContext, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const LandmarkRefsContext = createContext({});

export const useLandmarkRefsContext = () => useContext(LandmarkRefsContext);

const LandmarkRefsProvider = ({ children }) => {
    const pageHeadingRef = useRef();
    const formErrorsRef = useRef();
    const [fieldErrors, setFieldErrors] = useState({});
    const [displayFormErrors, setDisplayFormErrors] = useState(false);
    const [submitAttempted, setSubmitAttempted] = useState(false);

    const setPageHeadingRef = (newPageHeadingRef) => {
        if (newPageHeadingRef?.current) {
            pageHeadingRef.current = newPageHeadingRef.current;
        }
    };

    const registerFieldError = ({ id, errorMessage, fieldContainerRef, fieldRef }) => {
        setFieldErrors((prevState) => ({
            ...prevState,
            [id]: {
                reference: fieldRef,
                fieldContainerRef,
                message: errorMessage
            }
        }));
    };

    const unregisterFieldError = ({ id }) => {
        setFieldErrors((prevState) => {
            const newState = {
                ...prevState
            };
            delete newState[id];
            return newState;
        });
    };

    return (
        <LandmarkRefsContext.Provider
            value={{
                pageHeadingRef,
                setPageHeadingRef,
                formErrorsRef,
                registerFieldError,
                unregisterFieldError,
                fieldErrors,
                displayFormErrors,
                setDisplayFormErrors,
                submitAttempted,
                setSubmitAttempted
            }}
        >
            {children}
        </LandmarkRefsContext.Provider>
    );
};

LandmarkRefsProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export default LandmarkRefsProvider;
