/**
 * Adobe API client.
 *
 * @see Manage flicker with getOffer and applyOffer at the link below
 * https://experienceleague.adobe.com/en/docs/target-dev/developer/client-side/at-js-implementation/at-js/manage-flicker-with-atjs
 * for why we are directly setting opacity here
 *
 * Makes Adobe getOffers API call on module load for all configured mboxes for
 * Form Based Composer experiments, as well as pageLoad experiments configured
 * in Visual Experience Composer. The result is resolved and memoized in offers.
 */

import { maskAllHashParams, maskParam } from '../../utilities/utils';

const getOffers = () =>
    window.adobe?.target?.getOffers({
        request: {
            context: {
                channel: 'web',
                crossDomain: 'disabled',
                address: { url: maskAllHashParams(maskParam(window.location.href, 'quotation')) }
            },
            execute: { pageLoad: {} }
        }
    });

export const offers = () => {
    return getOffers()
        .then((response) => {
            window.adobe?.target?.applyOffers({ response: response });
            return response;
        })
        .catch((err) => {
            throw new Error(err); // caught by consumers
        });
};
