import { BRANDS, BrandedFooter, footerContent as plasticFooterContent } from '@cof/plastic-components';

import partners from '../../partners';
import pushToDataLayer, { EVENTS } from '../../utilities/dataLayer';
import useQueryParams from '../../utilities/useQueryParams';
import { addSParameterToLinkUrl } from '../../utilities/utils';

const Footer = () => {
    const { sParameter } = useQueryParams();
    const footerContent = Object.entries(plasticFooterContent).reduce((updatedFooter, [footerBrand, content]) => {
        const updatedLinks = content.links.map((link) => ({
            ...link,
            url: addSParameterToLinkUrl({ url: link.url, sParameter }),
            onClick: () => {
                pushToDataLayer({ event: EVENTS.FOOTER_LINK_CLICK, footerLinkName: link.text });
            }
        }));
        return {
            ...updatedFooter,
            [footerBrand === BRANDS.CAPITAL_ONE ? partners.DEFAULT : footerBrand.toLowerCase()]: {
                links: updatedLinks,
                copyright: content.copyright
            }
        };
    }, {});

    return <BrandedFooter allowList={null} footerContent={footerContent} />;
};

export default Footer;
