import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import useOfferData from '../../components/experiments/useOfferData';

export const AdobeExperimentContext = createContext({});

const AdobeExperimentProvider = ({ children }) => {
    const state = useOfferData();
    return <AdobeExperimentContext.Provider value={state}>{children}</AdobeExperimentContext.Provider>;
};

export const useAdobeExperiments = () => useContext(AdobeExperimentContext);

export default AdobeExperimentProvider;

AdobeExperimentProvider.propTypes = {
    children: PropTypes.node.isRequired
};
