/* eslint complexity: ["error", 8] */
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useBrand } from '@cof/plastic-components';
import { jwtDecode } from 'jwt-decode';
import merge from 'lodash.merge';

import { useFormData as useFormDataFetch } from '../../data';
import { CONSUMER_NAMES } from '../../utilities/constants';
import pushToDataLayer from '../../utilities/dataLayer/dataLayer';
import useQueryParams from '../../utilities/useQueryParams';
import useTechnicalError from '../../utilities/useTechnicalError';

export const FormDataContext = createContext();

const FormDataContextProvider = ({ children }) => {
    const [parsedFormData, setParsedFormData] = useState();
    const queryParams = useQueryParams();
    const [initialQueryParams] = useState(queryParams);
    const brand = useBrand();
    const consumerName = CONSUMER_NAMES[brand];
    const { oemc, vendorCode: urlVendorCode, sParameter, quotation, productId, pcr } = initialQueryParams;

    const [formDataResponse, formLoading, { error: formDataError }] = useFormDataFetch(
        {
            // keys must be in alphabetical order unless query param ordering middleware is added to Tyk API definition
            consumerName,
            oemc,
            sParameter,
            vc: urlVendorCode,
            quotation
        },
        parsedFormData
    );

    useTechnicalError(formDataError);

    const mergeResponseData = useCallback(
        (decodedFormDataResponse) => {
            const formDataConsumerName = decodedFormDataResponse?.consumerName;
            const formDataOemc = decodedFormDataResponse?.onlineEntryMethodCode;

            merge(decodedFormDataResponse, {
                consumerName: formDataConsumerName || consumerName,
                vendorCode: urlVendorCode || formDataOemc || oemc || 'CQC',
                oemc
            });
        },
        [consumerName, oemc, urlVendorCode]
    );

    useEffect(() => {
        if (formDataResponse == null) return;
        // parse the form data
        const { formResponse } = formDataResponse;
        const formDataResponseDecoded = jwtDecode(formResponse);

        const { formValues } = formDataResponseDecoded;

        mergeResponseData(formDataResponseDecoded);

        // all information has been injected at this point
        setParsedFormData(formDataResponseDecoded);

        pushToDataLayer({
            ccsNumber: productId,
            vendorCode: formDataResponseDecoded.vendorCode,
            consumerName: formDataResponseDecoded.consumerName,
            definitionId: formDataResponseDecoded.definition.id,
            journeyType: formValues && Object.entries(formValues).length > 0 ? 'pre-pop' : 'standard',
            pcr
        });
    }, [formDataResponse, consumerName, oemc, pcr, productId, urlVendorCode, mergeResponseData, quotation, sParameter]);

    const isLoading = formLoading || parsedFormData == null;

    return (
        <FormDataContext.Provider
            value={{
                formData: parsedFormData,
                formResponse: formDataResponse?.formResponse,
                formLoading: isLoading,
                quotation
            }}
        >
            {children}
        </FormDataContext.Provider>
    );
};
export const useFormData = () => useContext(FormDataContext);

export default FormDataContextProvider;

FormDataContextProvider.propTypes = {
    children: PropTypes.node.isRequired
};
