import { BaseLogo } from '@cof/plastic-components';
/* eslint-disable import/no-unresolved */

// ?react on svg path is required for svgr plugin used to import svgs as react components, so disable no-unresolved
import ClearScore from './clearscore-logo.svg?react';
import CompareTheMarket from './compare-the-market.svg?react';
import CreditKarma from './creditkarma-logo.svg?react';
import Experian from './experian-logo.svg?react';
import MoneyCoUk from './money-logo.svg?react';
import MoneySuperMarket from './moneysupermarket-logo.svg?react';
import TotallyMoney from './totally-money-logo.svg?react';
import USwitch from './uswitch-logo.svg?react';

const aggregatorsLogoMap = {
    MSM: (
        <BaseLogo
            RenderedLogo={MoneySuperMarket}
            height={[40, null, 60]}
            width={[48, null, 57]}
            logoProps={{ 'aria-hidden': true }}
            data-qa-id="money-supermarket-logo"
        />
    ),
    CLRS: (
        <BaseLogo
            RenderedLogo={ClearScore}
            height={[15, null, 25]}
            width={[113, null, 188]}
            logoProps={{ 'aria-hidden': true }}
            data-qa-id="clear-score-logo"
        />
    ),
    TOTM: (
        <BaseLogo
            RenderedLogo={TotallyMoney}
            height={[15, null, 25]}
            width={[93, null, 155]}
            logoProps={{ 'aria-hidden': true }}
            data-qa-id="totally-money-logo"
        />
    ),
    CTM: (
        <BaseLogo
            RenderedLogo={CompareTheMarket}
            height={[25, null, 40]}
            width={[141, null, 226]}
            logoProps={{ 'aria-hidden': true }}
            data-qa-id="compare-the-market-logo"
        />
    ),
    USWC: (
        <BaseLogo
            RenderedLogo={USwitch}
            height={[25, null, 40]}
            width={[85, null, 135]}
            logoProps={{ 'aria-hidden': true }}
            data-qa-id="uswitch-logo"
        />
    ),
    MCU: (
        <BaseLogo
            RenderedLogo={MoneyCoUk}
            height={[20, null, 35]}
            width={[95, null, 167]}
            logoProps={{ 'aria-hidden': true }}
            data-qa-id="money-couk-logo"
        />
    ),
    EXPR: (
        <BaseLogo
            RenderedLogo={Experian}
            height={[20, null, 35]}
            width={[61, null, 107]}
            logoProps={{ 'aria-hidden': true }}
            data-qa-id="experian-logo"
        />
    ),
    CK: (
        <BaseLogo
            RenderedLogo={CreditKarma}
            height={[15, null, 35]}
            width={[62, null, 97]}
            logoProps={{ 'aria-hidden': true }}
            data-qa-id="credit-karma-logo"
        />
    )
};

export default aggregatorsLogoMap;
