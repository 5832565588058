import { useBrand } from '@cof/plastic-components';

import useQueryParams from '../../utilities/useQueryParams';
import mkExperiment from '../mkExperiment';

const useCardType = () => {
    const brand = useBrand();
    const { setActive, isInTest } = mkExperiment({
        options: { control: 'P', test: ['Q', 'R'] },
        confName: 'experiments.cardType.enabled'
    });
    const { quotation, sParameter, oemc, vendorCode } = useQueryParams();
    setActive({ brand, sParameter, quotation, oemc, vendorCode });
    const { sParameter: sParameterPostSetup } = useQueryParams();
    return {
        isCardTypeTest: isInTest({ brand, sParam: sParameterPostSetup }),
        cardTypeTestVariant: sParameterPostSetup?.[1]
    };
};

export default useCardType;
