import { Box } from '@cof/plastic-components';
import { css, styled } from 'styled-components';

export const OuterCircle = styled.div`
    ${({ theme }) => css`
        content: '';
        position: absolute;
        background-color: ${theme.colors.capitalOne.teal450};
        border-radius: 50%;
        z-index: 4;
        width: 20rem;
        height: 20rem;
        top: -3rem;
        right: -10rem;

        @media (min-width: 500px) {
            width: 25rem;
            height: 25rem;
            top: -5rem;
            right: -10rem;
        }

        @media (min-width: 640px) {
            width: 30rem;
            height: 30rem;
            top: -5rem;
            right: -10rem;
        }

        ${theme.breakpoints.mq.tabletMin} {
            height: 39rem;
            width: 39rem;
            bottom: 2rem;
            right: -20rem;
            top: -9.5rem;
        }

        ${theme.breakpoints.mq.desktopMin} {
            height: 60rem;
            width: 60rem;
            bottom: 2rem;
            right: -44rem;
            top: -17rem;
        }

        ${theme.components.OuterCircle}
    `}
`;
OuterCircle.displayName = 'OuterCircle';

export const CelebrationBox = styled(Box)`
    ${({ theme }) => css`
        content: '';
        z-index: 4;
        position: absolute;
        width: 23rem;
        height: 23rem;
        border-radius: 50%;
        top: -2rem;
        right: -11rem;

        ${theme.breakpoints.mq.tabletMin} {
            bottom: 2rem;
            height: 72.2rem;
            right: -60rem;
            top: -13rem;
            width: 72.2rem;
        }
        ${theme.components.OuterCircle}
    `}
`;
