import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Button, Flex, Paragraph, THEME_CONSTANTS as TC } from '@cof/plastic-components';

import TitleSection, { TitleSectionHeading } from '../../components/TitleSection';
import { withText } from '../../text/textStore';
import pushToDataLayer, { EVENTS } from '../../utilities/dataLayer/dataLayer';
import useTimeout from '../../hooks/useTimeout/useTimeout';

const TimedOut = ({ Text, children }) => {
    const timedOut = useTimeout();
    useEffect(() => {
        if (timedOut) {
            pushToDataLayer({
                event: EVENTS.EXPIRY_PAGE_SHOW
            });
        }
    }, [timedOut]);
    return timedOut ? (
        <>
            <TitleSection>
                <TitleSectionHeading data-qa-id="timeout-heading">
                    <Text path="Title" />
                </TitleSectionHeading>
            </TitleSection>
            <Box py={['md', 'lg', 'xl']} maxWidth={TC.SCREEN_WIDTHS.SIX_COLUMN} mx={['lg', 'lg', 'auto']}>
                <Paragraph pb={['sm', 'md', 'lg']}>
                    <Text path="Paragraph1" />
                </Paragraph>
                <br />
                <Paragraph pb={['md', 'lg', 'xl']}>
                    <Text path="Paragraph2" />
                </Paragraph>

                <Flex justifyContent="center">
                    <Button as="a" href="/">
                        <Text path="Button.Text" />
                    </Button>
                </Flex>
            </Box>
        </>
    ) : (
        children
    );
};

TimedOut.propTypes = {
    Text: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
};

const TimedOutWithText = withText(`TimedOut`)(TimedOut);

TimedOutWithText.displayName = 'TimedOut';

export default TimedOutWithText;
