import PropTypes from 'prop-types';

import { Button, Heading, Link, Paragraph } from '@cof/plastic-components';
import { css, styled } from 'styled-components';

import BodySection from '../../components/BodySection';
import CheckYourCreditFileSection from '../../components/CheckYourCreditFileSection';
import Header from '../../components/Header';
import Main from '../../components/Main';
import TitleSection, { TitleSectionHeading } from '../../components/TitleSection';
import { withText } from '../../text/textStore';
import useBackground from '../../utilities/useBackground';

export const Playback = styled.div`
    ${({ theme }) => css`
        margin-bottom: ${theme.space.sm};
        padding: ${theme.space.sm};
        border: 0.1rem solid ${theme.colors.global.grey200};
        ${theme.components.Playback}
    `}
`;
Playback.displayName = 'Playback';

export const PlaybackHeading = (props) => <Heading level="3" data-qa-id="field-heading" {...props} />;
PlaybackHeading.displayName = 'PlaybackHeading';

export const PlaybackParagraph = styled(Paragraph)`
    padding: 0;
`;
PlaybackParagraph.displayName = 'PlaybackParagraph';

export const PlaybackGroup = styled.div`
    ${({ theme }) => css`
        margin-bottom: ${theme.space.sm};
    `}
`;
PlaybackGroup.displayName = 'PlaybackGroup';

const InformationMismatch = ({
    Text,
    Text: text,
    JsxText,
    title,
    firstName,
    lastName,
    dateOfBirth,
    employmentStatus,
    occupation,
    houseFlatNameNumber,
    streetName,
    addressLine2,
    townCity,
    county,
    postcode,
    yearsAtAddress
}) => {
    const background = useBackground('informationMismatch');
    return (
        <>
            <Header />
            <Main bg={background} data-qa-id="information-mismatch-page">
                <TitleSection>
                    <TitleSectionHeading>
                        <Text path="Title" />
                    </TitleSectionHeading>
                </TitleSection>
                <BodySection textAlign="center" data-qa-id="playback-section">
                    <Paragraph data-qa-id="preamble">
                        <Text path="Playback.Preamble" />
                    </Paragraph>
                    <Heading level="2" pb="sm">
                        <Text path="Playback.Heading" />
                    </Heading>
                    <Playback data-qa-id="playback">
                        <PlaybackGroup data-qa-id="full-name-group">
                            <PlaybackHeading>
                                <Text path="Playback.FullNameHeading" />
                            </PlaybackHeading>
                            <PlaybackParagraph>{`${title} ${firstName} ${lastName}`}</PlaybackParagraph>
                        </PlaybackGroup>
                        <PlaybackGroup data-qa-id="date-of-birth-group">
                            <PlaybackHeading>
                                <Text path="Playback.DateOfBirthHeading" />
                            </PlaybackHeading>
                            <PlaybackParagraph>{dateOfBirth.replace(/-/g, '/')}</PlaybackParagraph>
                        </PlaybackGroup>
                        <PlaybackGroup data-qa-id="your-work-group">
                            <PlaybackHeading>
                                <Text path="Playback.YourWorkHeading" />
                            </PlaybackHeading>
                            <PlaybackParagraph>{employmentStatus}</PlaybackParagraph>
                            {occupation && <PlaybackParagraph>{occupation}</PlaybackParagraph>}
                        </PlaybackGroup>
                        <PlaybackGroup data-qa-id="address-group">
                            <PlaybackHeading>
                                <Text path="Playback.AddressHeading" />
                            </PlaybackHeading>
                            <PlaybackParagraph>{houseFlatNameNumber}</PlaybackParagraph>
                            {streetName && <PlaybackParagraph>{streetName}</PlaybackParagraph>}
                            {addressLine2 && <PlaybackParagraph>{addressLine2}</PlaybackParagraph>}
                            <PlaybackParagraph>{townCity}</PlaybackParagraph>
                            {county && <PlaybackParagraph>{county}</PlaybackParagraph>}
                            <PlaybackParagraph>{postcode}</PlaybackParagraph>
                        </PlaybackGroup>
                        <PlaybackGroup data-qa-id="years-at-address-group">
                            <PlaybackHeading>
                                <Text path="Playback.YearsAtAddressHeading" />
                            </PlaybackHeading>
                            <PlaybackParagraph>{yearsAtAddress}</PlaybackParagraph>
                        </PlaybackGroup>
                    </Playback>
                    <Paragraph pb="md" data-qa-id="body">
                        <Text path="Playback.Body" />
                    </Paragraph>
                    <Button mt="md" data-qa-id="cta" as="a" href={text({ path: 'Playback.CTA.Target' })}>
                        <Text path="Playback.CTA.Text" />
                    </Button>
                </BodySection>
                <CheckYourCreditFileSection />
                <BodySection textAlign="center" data-qa-id="get-in-touch">
                    <Paragraph data-qa-id="body">
                        <JsxText
                            path="GetInTouch.Body"
                            params={{
                                GET_IN_TOUCH_LINK: (
                                    <Link href={text({ path: 'GetInTouch.Link.Target' })}>
                                        <Text path="GetInTouch.Link.Text" />
                                    </Link>
                                )
                            }}
                        />
                    </Paragraph>
                </BodySection>
            </Main>
        </>
    );
};

InformationMismatch.propTypes = {
    Text: PropTypes.func.isRequired,
    JsxText: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    dateOfBirth: PropTypes.string.isRequired,
    employmentStatus: PropTypes.string.isRequired,
    occupation: PropTypes.string,
    houseFlatNameNumber: PropTypes.string.isRequired,
    streetName: PropTypes.string,
    addressLine2: PropTypes.string,
    townCity: PropTypes.string.isRequired,
    county: PropTypes.string,
    postcode: PropTypes.string.isRequired,
    yearsAtAddress: PropTypes.string.isRequired
};

const WrappedInformationMismatch = withText('InformationMismatch')(InformationMismatch);

WrappedInformationMismatch.displayName = 'InformationMismatch';

export default WrappedInformationMismatch;
