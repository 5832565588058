import { Box } from '@cof/plastic-components';
import { css, styled } from 'styled-components';

const StyledMain = styled(Box)`
    ${({ theme }) => css`
        ${theme.components.Main};
    `}
`;

const Main = (props) => <StyledMain as="main" id="content" {...props} />;

Main.displayName = 'Main';

export default Main;
