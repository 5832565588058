import { useEffect } from 'react';

import useAxiosCallback from './useAxiosCallback';

const useAxios = (axiosConfig) => {
    const [exec, loading, { error, response, data }] = useAxiosCallback(axiosConfig);

    useEffect(() => {
        exec(axiosConfig);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [data, loading, { error, response }];
};

export default useAxios;
