import { brandProviderFactory, useBrand } from '@cof/plastic-components';

import partners, { getPartner } from './partners';

const BrandProvider = brandProviderFactory({
    brandProviderStrategy: getPartner,
    defaultBrand: partners.DEFAULT,
    brandAllowList: [
        partners.DEFAULT,
        partners.LUMA,
        partners.VERY,
        partners.LITTLEWOODS,
        partners.OCEAN,
        partners.THINKMONEY,
        partners.POSTOFFICE,
        partners.ASOS
    ]
});
BrandProvider.displayName = 'BrandProvider';

export { BrandProvider, useBrand };
