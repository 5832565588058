import { useEffect, useState } from 'react';

const useTimeout = (interval = 1440) => {
    const nowMinutes = Math.round(Date.now() / 60 / 1000);
    const [sessionStart] = useState(nowMinutes);
    const [isTimedOut, setIsTimedOut] = useState(false);

    useEffect(() => {
        const remainingTime = nowMinutes - sessionStart >= interval ? 0 : interval - (nowMinutes - sessionStart);

        setTimeout(
            () => {
                if (!document.title.includes('Session expired -')) {
                    document.title = `Session expired - ${document.title}`;
                }
                setIsTimedOut(true);
            },
            remainingTime * 60 * 1000
        );
    }, [interval, sessionStart, nowMinutes]);

    return isTimedOut;
};

export default useTimeout;
