import { capitalOneTheme } from '@cof/plastic-components';
import merge from 'lodash.merge';

import * as cap1Components from './components';
import * as globalComponentOverrides from '../shared';
import globalStyles from './globalStyles';

export default merge({}, capitalOneTheme, {
    components: { ...globalComponentOverrides, ...cap1Components },
    globalStyles
});
