import createStore from '@cof/acq-text-ukweb';
import withTemplate from '@cof/acq-text-ukweb/lib/plugins/hocs/withTemplate';

import withJsx from './hocs/withJsx';

const { Text, withText, TextStore } = createStore({
    hocs: {
        TemplateText: withTemplate,
        JsxText: withJsx
    }
});

export { Text, withText, TextStore };
