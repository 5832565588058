export const EVENTS = {
    MODAL_OPEN: 'Cookie modal open',
    BANNER_OPEN: 'Cookie banner open',
    GDPR_ACCEPT_ALL: 'GDPR Accept All',
    GDPR_REJECT_ALL: 'GDPR Reject All',
    GDPR_PREF_ALLOWS_1: 'GDPR Pref Allows 1',
    GDPR_PREF_ALLOWS_2: 'GDPR Pref Allows 2',
    GDPR_PREF_ALLOWS_3: 'GDPR Pref Allows 3'
};

export const addNewRelicPageAction = (actionName, actionObject) => {
    // eslint-disable-next-line no-unused-expressions
    window.newrelic?.addPageAction(actionName, actionObject);
};

export const addNewRelicError = (error, customAttributes) => {
    // eslint-disable-next-line no-unused-expressions
    window.newrelic?.noticeError(error, customAttributes);
};

export const setNewRelicAttribute = (attribute, value) => {
    // eslint-disable-next-line no-unused-expressions
    window.newrelic?.setCustomAttribute(attribute, value);
};
