const partners = {
    DEFAULT: 'capitalOne',
    LUMA: 'luma',
    VERY: 'very',
    LITTLEWOODS: 'littlewoods',
    OCEAN: 'ocean',
    THINKMONEY: 'thinkmoney',
    POSTOFFICE: 'postoffice',
    ASOS: 'asos'
};

export const getPartner = () => {
    const { search, hostname } = window.location;
    let partner;
    if (hostname === 'localhost') {
        const query = new URLSearchParams(search);
        partner = query.get('partner');
    } else if (hostname.indexOf('-') > -1) {
        [partner] = hostname.split('-');
    }

    return partner || partners.DEFAULT;
};

export default partners;
