import { THEME_CONSTANTS } from '@cof/plastic-components';
import { useTheme } from 'styled-components';

const { COLORS } = THEME_CONSTANTS;

const useBackground = (page, defaultBg = COLORS.global.white) => {
    const theme = useTheme();
    return theme?.pages?.[page]?.background ?? defaultBg;
};

export default useBackground;
