import PropTypes from 'prop-types';

import { LoadingSpinnerBranded, THEME_CONSTANTS, useBrand } from '@cof/plastic-components';

import partners from '../../partners';
import { getFeatureStyles } from '../../styles/shared';
import useIcon from '../../utilities/useIcon';

const featureMap = {
    [partners.DEFAULT]: {
        formSpinner: { color: THEME_CONSTANTS.COLORS.global.white },
        resultSpinner: { color: THEME_CONSTANTS.COLORS.capitalOne.pink250 },
        editSpinner: {
            color: THEME_CONSTANTS.COLORS.global.white,
            bg: THEME_CONSTANTS.COLORS.capitalOne.blue750
        }
    },
    [partners.LUMA]: {
        resultSpinner: { color: THEME_CONSTANTS.COLORS.global.white },
        editSpinner: {
            color: THEME_CONSTANTS.COLORS.luma.purple500,
            bg: THEME_CONSTANTS.COLORS.global.grey50
        }
    },
    [partners.VERY]: {
        editSpinner: {
            color: THEME_CONSTANTS.COLORS.very.pink500,
            bg: THEME_CONSTANTS.COLORS.global.grey50
        }
    },
    [partners.LITTLEWOODS]: {
        editSpinner: {
            color: THEME_CONSTANTS.COLORS.littlewoods.purple550,
            bg: THEME_CONSTANTS.COLORS.global.grey50
        }
    },
    [partners.POSTOFFICE]: {
        editSpinner: {
            color: THEME_CONSTANTS.COLORS.next.postOffice.red550,
            bg: THEME_CONSTANTS.COLORS.global.white
        }
    },
    [partners.OCEAN]: {
        editSpinner: {
            color: THEME_CONSTANTS.COLORS.ocean.blue800,
            bg: THEME_CONSTANTS.COLORS.global.grey50
        }
    },
    [partners.THINKMONEY]: {
        editSpinner: {
            color: THEME_CONSTANTS.COLORS.thinkMoney.blue550,
            bg: THEME_CONSTANTS.COLORS.global.grey50
        }
    }
};

const LoadingSpinner = ({ features = {}, children, ...props }) => {
    const brand = useBrand();
    const featureStyles = featureMap[brand]
        ? getFeatureStyles(features, featureMap[brand]).reduce((acc, el) => ({ ...acc, ...el }), {})
        : {};
    const icon = useIcon('spinner');

    return (
        <LoadingSpinnerBranded bg="transparent" Icon={icon} {...featureStyles} {...props}>
            {children}
        </LoadingSpinnerBranded>
    );
};

LoadingSpinner.propTypes = {
    children: PropTypes.node,
    features: PropTypes.shape({})
};

export default LoadingSpinner;
