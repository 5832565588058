import { createContext, useContext, useMemo, useState } from 'react';
const ConsumerNameContext = createContext();

function useAggregatorConsumerName() {
    const context = useContext(ConsumerNameContext);
    if (!context) {
        throw new Error(`useConsumerName must be used within a ConsumerNameProvider`);
    }
    return context;
}

function AggregatorConsumerNameProvider(props) {
    const [aggregatorConsumerName, setAggregatorConsumerName] = useState('');
    const value = useMemo(() => [aggregatorConsumerName, setAggregatorConsumerName], [aggregatorConsumerName]);
    return <ConsumerNameContext.Provider value={value} {...props} />;
}

export { AggregatorConsumerNameProvider, useAggregatorConsumerName };
