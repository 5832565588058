import PropTypes from 'prop-types';
import { PageRow, Paragraph, useBrand } from '@cof/plastic-components';
import { styled } from 'styled-components';
import TransunionSvg from '../../assets/shared/svg/logo_transunion.svg?react';
import Header from '../../components/Header';
import Main from '../../components/Main';
import CreditWiseSection from '../../components/pages/ScoreDecline/CreditWiseSection';
import GetToKnow from '../../components/pages/ScoreDecline/GetToKnow';
import TitleSection, { TitleSectionHeading, TitleSectionSubText } from '../../components/TitleSection';
import { withText } from '../../text/textStore';
import useBackground from '../../utilities/useBackground';

const TransunionLogo = styled(TransunionSvg)`
    display: inline-block;
    height: 4rem;
    margin: 0 0.4rem;
`;

TransunionLogo.displayName = 'TransunionLogo';

export const ScoreDeclineBody = () => {
    const brand = useBrand();

    if (brand === 'capitalOne') {
        return <CreditWiseSection />;
    }

    return <GetToKnow />;
};

const ScoreDecline = ({ Text }) => {
    const background = useBackground('scoreDecline');
    return (
        <>
            <Header />
            <Main bg={background} data-qa-id="score-decline-page">
                <TitleSection>
                    <TitleSectionHeading>
                        <Text path="Title" />
                    </TitleSectionHeading>
                    <TitleSectionSubText data-qa-id="subtitle">
                        <Text path="Subtitle" />
                    </TitleSectionSubText>
                </TitleSection>
                <>
                    <ScoreDeclineBody />
                    <PageRow>
                        <Paragraph textAlign="center">
                            Credit score by
                            <TransunionLogo aria-label="Transunion logo" />
                        </Paragraph>
                    </PageRow>
                </>
            </Main>
        </>
    );
};

ScoreDecline.propTypes = {
    Text: PropTypes.func.isRequired
};

const WrappedScoreDecline = withText('ScoreDecline')(ScoreDecline);

WrappedScoreDecline.displayName = 'ScoreDecline';

export default WrappedScoreDecline;
