import ukwebconfig from '@cof/ukweb-config/lib/clientConfig';

const getConfig = (endpoint) => {
    const config = ukwebconfig.get('orchestration')[endpoint];

    return {
        ...config,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
};

export default getConfig;
