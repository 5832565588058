import * as React from "react";
const SvgMoneyLogo = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 293.99 61.6", ...props }, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("style", null, ".cls-7{fill:#35454e}")), /* @__PURE__ */ React.createElement("g", { id: "Money_logo" }, /* @__PURE__ */ React.createElement("path", { d: "m34.6 19.33-.05-.09.05-.06L14.34 1a4 4 0 0 0-.75-.56A3 3 0 0 0 12 0h-.25a4.3 4.3 0 0 0-3.59 3.6v.48L8 4.39v1l.1 1.12.06.22 13.47 27 .1-.11.14.28Z", style: {
  fill: "#fec340"
} }), /* @__PURE__ */ React.createElement("path", { d: "m34.87 54.9-.1-35.9-.17.15-.21.24-12.66 14.24 9.48 18.91a4 4 0 0 0 3 2.35h.65Z", style: {
  fill: "#f4793b"
} }), /* @__PURE__ */ React.createElement("path", { d: "m61.56 6.59-.47-2.71A4.3 4.3 0 0 0 57.47 0h-.26a3 3 0 0 0-1.57.43 4 4 0 0 0-.75.55L34.6 19.19v.69l12.72 14 .15-.29.13.14Z", style: {
  fill: "#a64470"
} }), /* @__PURE__ */ React.createElement("path", { d: "m8.1 3.88-8 48a3 3 0 0 0-.06.58 2.25 2.25 0 0 0 2 2.45 1.8 1.8 0 0 0 1.38-.69l18.31-20.59-13-26A5.7 5.7 0 0 1 8 4.9a7 7 0 0 1 .08-1", style: {
  fill: "#faa73f"
} }), /* @__PURE__ */ React.createElement("path", { d: "M61.1 3.88a6 6 0 0 1 .08 1 5.7 5.7 0 0 1-.67 2.73l-13 26 18.36 20.6a1.8 1.8 0 0 0 1.38.69 2.25 2.25 0 0 0 2-2.45 3 3 0 0 0-.06-.58l-8-48", style: {
  fill: "#924a8b"
} }), /* @__PURE__ */ React.createElement("path", { d: "M34.6 19.19v35.72a3.9 3.9 0 0 0 3.4-2.38l9.48-18.91z", style: {
  fill: "#db4d75"
} }), /* @__PURE__ */ React.createElement("path", { d: "M135.41 23.52V43a3.1 3.1 0 0 1-3.11 3 3 3 0 0 1-3-3V24.74c0-5.68-1.76-8.53-6.89-8.53s-7.92 3.45-9.75 6.49V43a3.1 3.1 0 0 1-3.11 3 3 3 0 0 1-3-3V24.74c0-5.68-1.76-8.53-6.9-8.53s-7.91 3.45-9.74 6.49V43a3.1 3.1 0 0 1-3.11 3 3 3 0 0 1-3-3V14a3.1 3.1 0 0 1 3-3.12A3.15 3.15 0 0 1 89.83 14v2.77c1.89-2.63 5.07-6.22 11.49-6.22a11 11 0 0 1 10.35 7 13.7 13.7 0 0 1 12.45-7c7.97-.02 11.29 6.95 11.29 12.97M177.07 28.46c0 10.69-6.9 17.93-17.32 17.93s-17.32-7.24-17.32-17.93 6.83-17.93 17.32-17.93 17.32 7.25 17.32 17.93m-6.16-.07c0-8.93-6-12.24-11.16-12.24s-11.16 3.32-11.16 12.24 6 12.37 11.16 12.37 11.16-3.37 11.16-12.37M215.68 23.79V43a3.1 3.1 0 0 1-3.11 3 3 3 0 0 1-3-3V24.47c0-5.14-2.16-8.25-7.43-8.25-4.87 0-8.39 3.11-11.1 6.49V43a3.1 3.1 0 0 1-3.11 3 3 3 0 0 1-3-3V14a3 3 0 0 1 3-3.12A3.1 3.1 0 0 1 191 14v2.77c2.84-3.11 6.56-6.22 12.59-6.22 7.69-.02 12.09 6.01 12.09 13.24M254.62 40.9a3.1 3.1 0 0 1-1.75 2.51c-3 1.69-6 3-11.85 3-11 0-17.51-6.56-17.51-17.93 0-9.13 4.94-17.93 16.16-17.93 10.42 0 16.1 8.05 16.1 17a3 3 0 0 1-3.12 3.05h-22.92c.68 6.08 4.53 10 11.84 10a18.5 18.5 0 0 0 9.13-2.09 2.5 2.5 0 0 1 1.21-.27 2.65 2.65 0 0 1 2.71 2.66m-24.89-15.28h20c-.4-7-5.68-9.88-10-9.88s-9.59 2.85-10 9.88M293.78 15.13l-18.46 44.38a3 3 0 0 1-2.84 2.09 2.94 2.94 0 0 1-2.91-3 3 3 0 0 1 .21-1.08l4.4-11.29-12.72-31a4 4 0 0 1-.28-1.28 3.1 3.1 0 0 1 3-3.12 3.1 3.1 0 0 1 2.84 1.89l10.41 25.84 10.62-25.7A3 3 0 0 1 294 14a3 3 0 0 1-.22 1.13", className: "cls-7" })));
export default SvgMoneyLogo;
