import PropTypes from 'prop-types';

import {
    LoadableASOS3dHorizontalImage,
    LoadableASOS3dVerticalImage,
    LoadableCapitalOne3dCardImage,
    LoadableLittlewoods3dCardImage,
    LoadableLuma3dCardImage,
    LoadableOceanCardImage,
    LoadableThinkMoneyCardImage,
    LoadableVery3dCardImage
} from '@cof/plastic-components';

import partners from '../../partners';

const { DEFAULT, VERY, LUMA, LITTLEWOODS, OCEAN, THINKMONEY, ASOS } = partners;

const HorizontalCardImage = ({ brand, ...props }) => {
    const HorizontalCardComponent = {
        [DEFAULT]: () => <LoadableCapitalOne3dCardImage width="302px" {...props} />,
        [VERY]: LoadableVery3dCardImage,
        [LITTLEWOODS]: LoadableLittlewoods3dCardImage,
        [LUMA]: LoadableLuma3dCardImage,
        [OCEAN]: LoadableOceanCardImage,
        [THINKMONEY]: LoadableThinkMoneyCardImage,
        [ASOS]: () => <LoadableASOS3dHorizontalImage width={[null, null, '290px', '374px']} {...props} />
    }[brand || DEFAULT];

    return <HorizontalCardComponent {...props} />;
};
HorizontalCardImage.propTypes = {
    brand: PropTypes.string.isRequired
};

const VerticalCardComponent = {
    [ASOS]: LoadableASOS3dVerticalImage
};
const VerticalCardImage = ({ brand, ...props }) => {
    const CardComponent = VerticalCardComponent[brand];

    return <CardComponent {...props} />;
};
VerticalCardImage.propTypes = {
    brand: PropTypes.string.isRequired
};

const CardImage = ({ brand, vertical = false, ...props }) => {
    if (vertical && brand in VerticalCardComponent) {
        return <VerticalCardImage brand={brand} {...props} />;
    }
    return <HorizontalCardImage brand={brand} {...props} />;
};
CardImage.propTypes = {
    brand: PropTypes.string.isRequired,
    vertical: PropTypes.bool
};

export default CardImage;
