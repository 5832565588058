import { BaseLogo } from '@cof/plastic-components';

const Logo = () => (
    <svg fill="none" height="62" viewBox="0 0 123 62" width="123" xmlns="http://www.w3.org/2000/svg">
        <title>money helper logo</title>
        <path
            d="m12.371 51.9712-3.19025-3.1833 3.93035-3.9218h-4.69042c-4.9504 0-8.42068-3.333-8.42068-8.093v-5.8278h4.81039l.01 5.1792c0 2.4947 1.64014 4.2311 4.00033 4.2311h4.28038l-3.93035-3.9118 3.19025-3.1933 6.0205 6.0074c1.8501 1.8461 1.8501 4.8598 0 6.7059z"
            fill="#c82a87"
        />
        <g fill="#0f19a0">
            <path d="m0 0h5.82047l7.02053 16.7948 7.0506-16.7948h5.7905v24.0396h-4.8204v-14.66925l-6.1005 14.66925h-3.8203l-6.12051-14.66925v14.66925h-4.82039z" />
            <path
                clipRule="evenodd"
                d="m37.2838 6.45703c-5.3404 0-9.1608 3.78207-9.1608 8.93127s3.8204 8.9313 9.1608 8.9313 9.1607-3.7821 9.1607-8.9313-3.8203-8.93127-9.1607-8.93127zm-.0107 4.09107c2.58 0 4.34 1.9558 4.34 4.8395 0 2.8438-1.75 4.8095-4.34 4.8095-2.58 0-4.34-1.9557-4.34-4.8095 0-2.8837 1.76-4.8395 4.34-4.8395z"
                fillRule="evenodd"
            />
            <path d="m48.3945 6.72473h4.8204v2.40495c.9601-1.68646 2.8903-2.68437 5.2705-2.68437 3.9203 0 6.4705 2.8141 6.4705 6.80569v10.7874h-4.8204v-9.7196c0-2.0956-1.3401-3.6024-3.3403-3.6024-2.1002 0-3.5803 1.6166-3.5803 3.8519v9.4801h-4.8204z" />
            <path
                clipRule="evenodd"
                d="m75.9754 24.3196c4.2704 0 7.5706-2.405 8.2607-6.1471h-4.6904c-.38 1.4769-1.6501 2.2353-3.5803 2.2353-2.4102 0-3.9603-1.5168-4.3003-3.9517h12.461c.04-.3792.07-.8982.07-1.5468 0-3.9517-2.4402-8.45227-8.5707-8.45227-5.8905 0-8.5707 4.50057-8.5707 8.90137 0 4.3608 3.0302 8.9612 8.9207 8.9612zm3.4392-10.8878h-7.68c.42-2.4047 1.72-3.4724 3.89-3.4724 2.31 0 3.65 1.5466 3.79 3.4724z"
                fillRule="evenodd"
            />
            <path d="m95.558 23.9704-2.4402 7.0752h-4.5804l2.5502-7.145-6.3005-17.17404h4.8904l3.6403 11.16654 3.6203-11.16654h4.9199z" />
            <path d="m42.5238 30.9453v24.0496h-5.2004v-10.0689h-9.9208v10.0689h-5.1604v-24.0496h5.1604v9.55h9.9208v-9.55z" />
            <path
                clipRule="evenodd"
                d="m53.8446 55.2649c4.2703 0 7.5706-2.405 8.2606-6.1471h-4.6904c-.38 1.4769-1.6501 2.2353-3.5802 2.2353-2.4102 0-3.9604-1.5168-4.3004-3.9517h12.461c.04-.3792.07-.8981.07-1.5468 0-3.9517-2.4402-8.4523-8.5707-8.4523-5.8905 0-8.5707 4.5006-8.5707 8.9014 0 4.3608 3.0303 8.9612 8.9208 8.9612zm3.4392-10.8877h-7.68c.42-2.4048 1.73-3.4625 3.89-3.4725 2.31 0 3.65 1.5466 3.79 3.4725z"
                fillRule="evenodd"
            />
            <path d="m64.7852 30.1562h4.79039v24.8379h-4.79039z" />
            <path
                clipRule="evenodd"
                d="m77.4154 37.6818h-4.7804-.01v24.319h4.7804v-8.5521c.9301 1.1675 2.6202 1.8162 4.5804 1.8162 5.0004 0 8.3706-3.5426 8.3706-8.9313s-3.1002-8.9313-7.9506-8.9313c-2.2402 0-3.9603.8283-4.9904 2.1655zm3.9996 3.8216c2.51 0 4.23 1.9158 4.23 4.8395 0 2.8837-1.72 4.7995-4.23 4.8095-2.58 0-4.27-1.9258-4.27-4.8095 0-2.9137 1.69-4.8395 4.27-4.8395z"
                fillRule="evenodd"
            />
            <path
                clipRule="evenodd"
                d="m101.009 55.2649c4.27 0 7.57-2.405 8.26-6.1471h-4.69c-.38 1.4769-1.65 2.2353-3.58 2.2353-2.4106 0-3.9607-1.5168-4.3007-3.9517h12.4607c.04-.3792.07-.8981.07-1.5468 0-3.9517-2.44-8.4523-8.57-8.4523-5.8909 0-8.5711 4.5006-8.5711 8.9014 0 4.3608 3.0302 8.9612 8.9211 8.9612zm3.439-10.8877h-7.6801c.42-2.4048 1.72-3.4625 3.8901-3.4725 2.31 0 3.65 1.5466 3.79 3.4725z"
                fillRule="evenodd"
            />
            <path d="m120.14 41.6613c-2.381 0-3.441 1.9559-3.441 4.9097v8.4124h-4.82v-17.3037h4.82v2.8839c.93-2.1654 2.24-2.8839 4.341-2.8839h1.96v3.9816z" />
        </g>
    </svg>
);

const MoneyHelperLogo = (props) => <BaseLogo RenderedLogo={Logo} data-qa-id="money-helper-logo" {...props} />;

export default MoneyHelperLogo;
