import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router';

import { useBrand } from '@cof/plastic-components';

import { shouldShowTechError } from '../../data';
import TechnicalError from '../../pages/TechnicalError';

const ErrorBlockedRouter = ({ children }) => {
    const brand = useBrand();
    const shouldShowError = shouldShowTechError(brand);

    return <Router>{shouldShowError ? <TechnicalError /> : children}</Router>;
};

ErrorBlockedRouter.propTypes = { children: PropTypes.node.isRequired };

export default ErrorBlockedRouter;
