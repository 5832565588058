import { capitalOneTheme } from '@cof/plastic-components';
import merge from 'lodash.merge';
import cssSystem from '@styled-system/css';
import { css } from 'styled-components';

/**
 * Return feature styles based on a feature and mapping
 */
export const getFeatureStyles = (features, featureMap) =>
    features && typeof features === 'object'
        ? Object.keys(features).map((feature) => (features[feature] ? featureMap[feature] : undefined))
        : null;

// export necessary plastic component styles
const { radioGroup: pRadioGroup } = capitalOneTheme.components;

export const VisuallyHidden = css`
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
`;

export const Field = css`
    border: none;
    padding: 0;
    margin: 0;
`;

/**
 * PLASTIC COMPONENT UPDATES BELOW HERE
 */

export const radioGroup = merge({}, pRadioGroup, {
    radioButton: {
        horizontal: () => css`
            ${pRadioGroup.radioButton.horizontal}
            height: 2.4rem;
            width: 2.4rem;
        `
    }
});

export const checkbox = {
    square: {
        label: ({ theme }) => {
            const colors = theme.componentColors.radioGroup;
            return css`
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: ${theme.space.xs};
                box-sizing: border-box;
                height: 4.8rem;
                z-index: 1;
                width: 100%;
                border-radius: 0.4rem;
                border: 0.2rem solid ${colors.squareRadioButtonBorder};
                background: ${colors.radioButtonBackground};
                & > span {
                    color: inherit;
                    font-weight: inherit;
                    font-size: inherit;
                }
                ${cssSystem({
                    width: 'auto',
                    fontSize: [theme.fontSizes.textMediumMobile, null, theme.fontSizes.textSmallDesktop],
                    fontWeight: theme.fontWeights.semiBold,
                    color: colors.squareUncheckedText
                })}

                &:has(input[type='checkbox']:checked:hover) {
                    background-color: ${colors.squareChecked};
                    border-color: ${colors.squareChecked};
                    color: ${colors.squareCheckedText};
                }
                &:has(input[type='checkbox']:checked) {
                    background-color: ${colors.squareChecked};
                    border-color: ${colors.squareChecked};
                    color: ${colors.squareCheckedText};
                }
                &:has(input[type='checkbox']:hover) {
                    background-color: ${theme.componentColors.table.row.border};
                    color: ${colors.squareUncheckedText};
                }
                &:has(input[type='checkbox']:focus) {
                    ${theme.components.forms.borderStyle}
                }
            `;
        },
        hidden: css`
            position: absolute;
            opacity: 0;
            height: 1px;
            width: 1px;
        `
    }
};
