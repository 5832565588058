import { Paragraph } from '@cof/plastic-components';
import { css, styled } from 'styled-components';

const TitleSectionSubText = styled(Paragraph).attrs(() => ({ size: 'large' }))`
    ${({ theme }) => css`
        max-width: 90%;
        margin: auto;
        color: ${theme.colors.global.white};
        ${theme.components.BodyWidth}
        ${theme.components.TitleSectionSubText}
    `}
`;
TitleSectionSubText.displayName = 'TitleSectionSubText';

export default TitleSectionSubText;
