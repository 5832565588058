/* eslint complexity: ["error", 7] */
import config from '@cof/ukweb-config/lib/clientConfig';
import partners from '../partners';

/**
 * @param {Object} args - Experiment arguments
 * @param {Object} args.options - Describes the experiment identifiers
 * @param {string} args.options.control
 * @param {string} args.options.test
 * @param {string} args.confName
 * @param {function=} args.audience - Takes any object passed via {@link setActive} and returns boolean indicating in audience
 * @returns {any} experiment
 */
const mkExperiment = ({ options, confName, audience }) => {
    const { control, test } = options;

    const validated = typeof control === 'string' && (typeof test === 'string' || Array.isArray(test));
    if (!validated) throw new Error('options object requires control and test properties supplied as strings');

    const inExperiment = (value = '') => {
        if (Array.isArray(test)) {
            return value?.[1] === control || test.includes(value?.[1]);
        }
        return value?.[1] === control || value?.[1] === test;
    };

    const defaultAudience = ({ brand }) => {
        return brand === partners.DEFAULT;
    };

    const audienceOrDefault = audience || defaultAudience;

    const getLatestSParam = (sParameter, vendorCode, oemc) => {
        const newVendorCode = vendorCode || oemc || 'CQC';
        return sParameter ?? `xxxxxxxx${newVendorCode}`;
    };

    const name = confName;

    const isInTest = ({ sParam, ...params }) => {
        if (Array.isArray(test)) {
            return !!config.get(confName) && audienceOrDefault(params) && test.includes(sParam?.[1]);
        }
        return !!config.get(confName) && audienceOrDefault(params) && sParam?.[1] === test;
    };

    const setActive = (params) => {
        const { sParameter, vendorCode, oemc, quotation, rollout } = params;
        const latestParameter = getLatestSParam(sParameter, vendorCode, oemc);

        if (
            !config.get(confName) ||
            inExperiment(latestParameter) ||
            !audienceOrDefault(params) ||
            quotation ||
            rollout
        ) {
            return;
        }
        const randomInt = Math.random();
        const newTestState =
            randomInt <= 1 / (test.length + 1) ? control : test[Math.floor(randomInt * (test.length + 1) - 1)];
        const updated = latestParameter.replace(/(.)(.)(.*)/, `$1${newTestState}$3`);
        const searchParams = new URLSearchParams(window.location.search);
        const hashParams = window.location.hash.toString();
        searchParams.set('s', updated);
        window.history.replaceState('', '', `${window.location.pathname}?${searchParams.toString()}${hashParams}`);
    };

    return {
        name,
        isInTest,
        setActive
    };
};

export default mkExperiment;
