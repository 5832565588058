export const EVENTS = {
    ACCORDION_CLICK: 'accordionClick',
    EXPIRY_PAGE_SHOW: 'expiryPageShown',
    PERMISSION_CHECKBOX_SELECT: 'permisssionCheckBoxSelect',
    FIELD_FOCUS: 'fieldFocus',
    FOOTER_LINK_CLICK: 'footerLinkClick',
    MODAL_OPEN: 'modalOpen',
    SUBMISSION_RESULT: 'submissionResult',
    CTA_BUTTON_CLICK: 'ctaButtonClick',
    FORM_ERRORS: 'formErrors',
    QUOTE_SESSION: 'quoteSession',
    PAGE_VIEW: 'pageView',
    QUOTE_SUBMISSION: 'quoteSubmission',
    BACK_BUTTON_CLICK: 'backButtonClick',
    GDPR_PREF_ALLOWS_2: 'GDPR Pref Allows 2',
    GDPR_PREF_ALLOWS_3: 'GDPR Pref Allows 3',
    GDPR_PREF_ALLOWS_2_3: 'GDPR Pref Allows 2,3'
};

const pushToDataLayer = (values) => {
    if (values?.event && !Object.values(EVENTS).includes(values.event)) {
        throw new Error('Unknown event');
    }
    if (window.dataLayer) {
        window.dataLayer.push(values);
    }
};

export default pushToDataLayer;
