import { HourGlassIcon, LockIcon } from '@cof/plastic-components';
import { useTheme } from 'styled-components';

const useIcon = (page, submitting, defaultIcon = LockIcon) => {
    const theme = useTheme();
    const themeIcon = theme?.pages?.[page]?.icon;

    if (submitting === true) {
        return themeIcon ?? HourGlassIcon;
    }
    return themeIcon ?? defaultIcon;
};

export default useIcon;
