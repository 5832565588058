import {
    BRANDS,
    CookieBanner,
    cookieBannerPolicyLinks as plasticCookieBannerPolicyLinks
} from '@cof/plastic-components';
import { addSParameterToLinkUrl } from '../../utilities/utils';

const TransformedCookieBanner = (props) => {
    const policyLinks = Object.entries(plasticCookieBannerPolicyLinks).reduce((updatedPolicyLinks, [brand, link]) => {
        const query = new URLSearchParams(window.location.search);
        const sParameter = query.get('s') || undefined;
        const url = new URL(link);
        const urlWithsParam = addSParameterToLinkUrl({ url, sParameter });
        return {
            ...updatedPolicyLinks,
            [brand === BRANDS.CAPITAL_ONE ? 'capitalOne' : brand.toLowerCase()]: urlWithsParam
        };
    }, {});

    return <CookieBanner allowRejectAll policyLinks={policyLinks} {...props} />;
};

export default TransformedCookieBanner;
