import { useState } from 'react';

import axios from 'axios';

function applyArgToAxiosConfig(arg, config) {
    let resultConfig = typeof config === 'function' ? config(arg) : config;
    if (typeof resultConfig === 'string') {
        resultConfig = { url: resultConfig };
    }

    return resultConfig;
}

const useAxiosCallback = (axiosConfigOrFactory) => {
    const [data, setData] = useState(undefined);
    const [response, setResponse] = useState(undefined);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [execCount, setExecCount] = useState(0);

    const fetchData = async (params) => {
        try {
            setLoading(true);
            const result = await axios.request(params);
            setResponse(result);
            setData(result.data);
        } catch (err) {
            setError(err);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const exec = (reqData) => {
        setExecCount(execCount + 1);
        fetchData(applyArgToAxiosConfig(reqData, axiosConfigOrFactory));
    };

    return [exec, loading, { error, response, data, execCount }];
};

export default useAxiosCallback;
