import PropTypes from 'prop-types';

import { SkipLink as PlasticSkipLink } from '@cof/plastic-components';
import { styled } from 'styled-components';

import { withText } from '../../text/textStore';

const BaseSkipLink = styled(PlasticSkipLink).attrs(() => ({
    'href': '#content',
    'role': 'link',
    'data-qa-id': 'skip-to-content'
}))`
    z-index: 999;
`;
BaseSkipLink.displayName = 'BaseSkipLink';

const SkipLink = ({ Text }) => {
    return (
        <BaseSkipLink>
            <Text path="Text" />
        </BaseSkipLink>
    );
};

SkipLink.propTypes = {
    Text: PropTypes.func.isRequired
};

const WrappedSkipLink = withText('SkipLink')(SkipLink);

WrappedSkipLink.displayName = 'SkipLink';

export default WrappedSkipLink;
