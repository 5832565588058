/* eslint complexity: ["error", 8] */
import AffordabilityDecline from '../../../pages/AffordabilityDecline';
import AlreadyAppliedDecline from '../../../pages/AlreadyAppliedDecline';
import CreditBureauDecline from '../../../pages/CreditBureauDecline';
import InformationMismatch from '../../../pages/InformationMismatch';
import ScoreDecline from '../../../pages/ScoreDecline';
import { decisions } from '../../../utilities/constants';

const getResultPage = (decision, userData) => {
    switch (decision) {
        case decisions.INFORMATION_MISMATCH:
            return (
                <InformationMismatch
                    title={userData.title}
                    firstName={userData.firstName}
                    lastName={userData.lastName}
                    dateOfBirth={userData.dateOfBirth}
                    employmentStatus={userData.employmentStatus}
                    occupation={userData.occupation}
                    houseFlatNameNumber={userData.houseFlatNameNumber}
                    streetName={userData.streetName}
                    addressLine2={userData.addressLine2}
                    townCity={userData.townCity}
                    county={userData.county}
                    postcode={userData.postcode}
                    yearsAtAddress={userData.yearsAtAddress}
                />
            );

        case decisions.SCORE_DECLINE:
            return <ScoreDecline />;

        case decisions.CREDIT_BUREAU_DECLINE:
            return <CreditBureauDecline />;

        case decisions.AFFORDABILITY_DECLINE:
            return <AffordabilityDecline />;

        case decisions.ALREADY_APPLIED_DECLINE:
            return <AlreadyAppliedDecline firstName={userData.firstName} />;

        default:
        case decisions.ERROR:
            throw new Error();
    }
};

export default getResultPage;
