import { useState, useEffect } from 'react';

/**
 * Awaits a promise, returns hook state to indicate progress
 * @param {function(): Promise} promise - Promise to resolve
 * @param {function(): Promise} [initial] - An optional initial promise to await before
 * starting to capture state
 * @returns {Object} Returns the state of the promise resolution
 */
const useAsyncData = (promise, initial = () => Promise.resolve()) => {
    useEffect(() => {
        const fetchData = async () => {
            try {
                const init = await initial();
                setState((s) => ({ ...s, fetching: true, initialising: false }));
                const data = await promise(init);
                if (data) setState((s) => ({ ...s, data }));
            } catch (error) {
                setState((s) => ({ ...s, error }));
            } finally {
                setState((s) => ({ ...s, fetching: false, initialising: false }));
            }
        };

        fetchData();
    }, [promise]);

    const [state, setState] = useState({ fetching: false, initialising: true });
    return state;
};

export default useAsyncData;
