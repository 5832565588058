/* eslint complexity: ["error", 8] */
import { useEffect, useState, lazy, Suspense } from 'react';
import { Route, Routes, useNavigate } from 'react-router';
import { styled } from 'styled-components';
import PropTypes from 'prop-types';
import { useBrand } from '../../BrandContext';
import { useFormSubmit } from '../../data';
import TimedOut from '../../pages/TimedOut';
import { useAggregatorConsumerName } from '../../utilities/aggregatorConsumerNameProvider';
import { journeys, routes } from '../../utilities/constants';
import pushToDataLayer, { EVENTS } from '../../utilities/dataLayer';
import { setNewRelicAttribute } from '../../utilities/newRelic';
import { SessionProvider } from '../../utilities/session';
import useQueryParams from '../../utilities/useQueryParams';
import useTechnicalError from '../../utilities/useTechnicalError';
import { isAggregatorConsumer, useSinglePage } from '../../utilities/utils';
import useBackground from '../../utilities/useBackground';
import { getValue, mutateFormValues } from '../../utilities/formValuesMutation/formValuesMutation';
import Footer from '../Footer';
import { useFormData } from '../FormDataContext';
import Header from '../Header';
import Result from './Result';
import BaseMain from '../Main';
import QuotationHeroSection from '../QuotationHeroSection';
import LoadingSpinner from '../LoadingSpinner';
import { StyledBox } from '../../pages/QuotationForm/StyledComponents';
import { useExperiments } from '../../experiments/ExperimentContext';

export const destructureSParameter = (sParameter) => {
    const doubleClickId = sParameter?.substring(0, 8);
    return { doubleClickId };
};

const FormSpinnerWrapper = styled.div`
    padding: 10rem 0;
`;
FormSpinnerWrapper.displayName = 'FormSpinnerWrapper';

const QuotationForm = lazy(() => import('../../pages/QuotationForm'));

const FormSpinner = ({ formLoading, forceSpinner, children }) => {
    return formLoading || forceSpinner ? (
        <StyledBox minHeight={3000} flex={1}>
            <FormSpinnerWrapper>
                <LoadingSpinner Icon={null} features={{ formSpinner: true }} />
            </FormSpinnerWrapper>
        </StyledBox>
    ) : (
        children
    );
};

const EditSpinner = ({ formLoading, quotation, children }) => {
    return formLoading && quotation ? <LoadingSpinner features={{ editSpinner: true }} /> : children;
};

const Quotation = () => {
    const { formData, formLoading, formResponse, quotation } = useFormData();
    // TODO have the `FormDataLoadingBlock` before this component so that we are certain it has been loaded
    // This is a limitation right now because a lot of the page can be rendered while we wait for the response
    // but that is deeply nested in the tree and needs to be abstracted so that it is rendered before we get here
    const sessionId = formData?.sessionId;
    const definition = formData?.definition;
    const vendorCode = formData?.vendorCode;
    const consumerName = formData?.consumerName;
    const formValues = formData?.formValues;
    const oemc = formData?.oemc;
    const [btOfferedVal, setBtOfferedVal] = useState();
    const [, setAggregatorConsumerName] = useAggregatorConsumerName();
    const navigate = useNavigate();
    const brand = useBrand();
    const rawInitialQueryParams = useQueryParams();
    const [initialQueryParam] = useState(rawInitialQueryParams);
    const {
        campaign,
        productId,
        sParameter,
        channel,
        btNotOffered,
        firstName,
        lastName,
        pcr,
        vendorCode: urlVendorCode
    } = initialQueryParam;
    const background = useBackground('quotation');

    const { isCardTypeTest } = useExperiments();
    const [progressBarVisible, setProgressBarVisible] = useState(false);

    const conditionalSetNewRelicAttribute = (attributeName, attributeValue) => {
        // set new relic attribute if that attribute is not undefined
        if (attributeValue) setNewRelicAttribute(attributeName, attributeValue);
    };

    const experimentVariant = /[A-Z]/.test(sParameter?.charAt(1)) ? sParameter?.charAt(1) : undefined;
    const webpageChar = /[A-Z]/.test(sParameter?.charAt(5)) ? sParameter?.charAt(5) : undefined;

    useEffect(() => {
        conditionalSetNewRelicAttribute('brand', brand);
        conditionalSetNewRelicAttribute('oemc', oemc);
        conditionalSetNewRelicAttribute('vendorCode', vendorCode);
        conditionalSetNewRelicAttribute('productId', productId);
        conditionalSetNewRelicAttribute('experimentVariant', experimentVariant);
    }, [brand, oemc, vendorCode, productId, experimentVariant]);

    if (btNotOffered && btOfferedVal === undefined) {
        setBtOfferedVal(false);
    }

    const { doubleClickId } = destructureSParameter(sParameter);

    const journey = journeys.STANDARD; // Sort this out later

    useEffect(() => {
        if (sessionId && window?.newrelic?.setUserId != null) {
            window.newrelic.setUserId(sessionId);
        }
    }, [sessionId]);

    const [submitForm, submitting, { response, error: submitFormError }] = useFormSubmit();

    useEffect(() => {
        if (submitting) {
            navigate(routes.result);
        }

        if (isAggregatorConsumer(consumerName)) {
            setAggregatorConsumerName(consumerName);
        }
    }, [submitting, consumerName, setAggregatorConsumerName]);

    const isPartnerAggsEdit = useSinglePage(brand, consumerName);

    const error = submitFormError;

    useTechnicalError(error);

    if (submitFormError) {
        pushToDataLayer({
            event: EVENTS.SUBMISSION_RESULT,
            result: 'error'
        });
        return null;
    }

    const getCashAdvance = (values) => {
        const cashAdvanceResponse = getValue('cash-advance', values);
        if (cashAdvanceResponse === 'Y') {
            return 'yes';
        }
        if (cashAdvanceResponse === 'N') {
            return 'no';
        }
        return 'unanswered';
    };

    const handleSubmit = (values) => {
        const formData = {
            formResponse,
            response: mutateFormValues(values, definition, isPartnerAggsEdit),
            onlineEntryMethodCode: vendorCode,
            productId,
            sParameter,
            marketingOptIn: isPartnerAggsEdit ? 'false' : getValue('marketing-permission', values),
            requoteOptIn: isPartnerAggsEdit ? 'false' : getValue('future-soft-search-permission', values),
            cashAdvance: getCashAdvance(values),
            visitorId: window.Zuko?.getVisitorId(),
            preTaxIncomeDuration: 'yearly', // hoping to clear these up once we have permission to remove them from the dataflake
            otherIncomeDuration: 'yearly'
        };
        if (isCardTypeTest) {
            formData.cardType = getValue('cardTypeTest', values)?.join(',') ?? '';
        }
        submitForm(formData);
    };

    return (
        <Routes>
            <Route
                path={routes.result}
                element={
                    <>
                        <Result
                            responseData={response?.data}
                            submitting={submitting}
                            pcr={pcr}
                            campaign={campaign}
                            channel={channel}
                            vendorCode={vendorCode}
                        />
                        <Footer />
                    </>
                }
            />
            <Route
                path="*"
                element={
                    <EditSpinner formLoading={formLoading} quotation={quotation}>
                        <SessionProvider {...{ sessionId, journey }}>
                            <Header mainPage={true} />
                            <BaseMain bg={background} data-qa-id="quotation-form-page" zIndex="2">
                                <TimedOut>
                                    <QuotationHeroSection
                                        isPartnerAggsEdit={isPartnerAggsEdit}
                                        urlVendorCode={urlVendorCode}
                                        isEdit={!!quotation}
                                    />
                                    <FormSpinner formLoading={formLoading}>
                                        {/* FormSpinner waits for /form to return before rendering the form */}
                                        <Suspense fallback={<FormSpinner forceSpinner />}>
                                            {/* Suspense waits for the chunk containing <QuotationForm> to be returned, showing the spinner in the meantime */}
                                            {/* There's no way to know whether the lazy load has happened outside the suspense component*/}
                                            <QuotationForm
                                                definitionId={definition?.id}
                                                onlineEntryMethodCode={vendorCode}
                                                prePopValues={formValues}
                                                stages={definition?.stages ?? []}
                                                formLoading={formLoading}
                                                formResponse={formResponse}
                                                onSubmit={handleSubmit}
                                                doubleClickId={doubleClickId}
                                                consumerName={consumerName}
                                                productOptions={{ btOfferedVal }}
                                                firstName={firstName}
                                                lastName={lastName}
                                                isPartnerAggsEdit={isPartnerAggsEdit}
                                                experimentChar={experimentVariant}
                                                webpageChar={webpageChar}
                                                onProgressBarVisible={() => setProgressBarVisible(true)}
                                                isProgressBarVisible={progressBarVisible}
                                            />
                                        </Suspense>
                                    </FormSpinner>
                                </TimedOut>
                            </BaseMain>
                            <Footer />
                        </SessionProvider>
                    </EditSpinner>
                }
            />
        </Routes>
    );
};

FormSpinner.propTypes = {
    formLoading: PropTypes.bool,
    forceSpinner: PropTypes.bool,
    children: PropTypes.node
};
EditSpinner.propTypes = {
    formLoading: PropTypes.bool,
    quotation: PropTypes.string,
    children: PropTypes.node
};

export default Quotation;
