import PropTypes from 'prop-types';

import { Box, Button, Flow, FlowPoint, Heading, Link, Paragraph, THEME_CONSTANTS } from '@cof/plastic-components';
import { styled } from 'styled-components';

import BodySection from '../../components/BodySection';
import Header from '../../components/Header';
import Main from '../../components/Main';
import TitleSection, { TitleSectionHeading, TitleSectionSubText } from '../../components/TitleSection';
import { withText } from '../../text/textStore';
import useBackground from '../../utilities/useBackground';
import MoneyHelperBanner from '../../components/MoneyHelperBanner';

const { COLORS } = THEME_CONSTANTS;

const CreditBureauDeclineCreditRatingWrapper = styled(Box)`
    ${({ theme }) => theme.components.CreditBureauDeclineCreditRatingWrapper}
`;

const CreditBureauDecline = ({ Text, Text: text, JsxText }) => {
    const background = useBackground('creditBureauDecline');
    return (
        <>
            <Header />
            <Main bg={background} data-qa-id="credit-bureau-decline-page">
                <TitleSection>
                    <TitleSectionHeading>
                        <Text path="Title" />
                    </TitleSectionHeading>
                    <TitleSectionSubText data-qa-id="subtitle">
                        <Text path="Subtitle" />
                    </TitleSectionSubText>
                </TitleSection>
                <BodySection data-qa-id="credit-file">
                    <Paragraph data-qa-id="body1">
                        <Text path="Body1" />
                    </Paragraph>
                    <MoneyHelperBanner />
                    <Heading data-qa-id="heading" level="2">
                        <Text path="CreditFile.Heading" />
                    </Heading>
                    <Paragraph data-qa-id="body2" whiteSpace="pre-wrap">
                        <Text path="CreditFile.Body" />
                    </Paragraph>
                    <Flow
                        data-qa-id="flow"
                        variant={background === COLORS.global.white ? 'light' : 'dark'}
                        my="lg"
                        px={0}
                    >
                        {[1, 2, 3, 4].map((point) => (
                            <FlowPoint key={point} data-qa-id="flow-point">
                                {text({ path: `Points.Point${point}` })}
                            </FlowPoint>
                        ))}
                    </Flow>
                    <Paragraph data-qa-id="body3">
                        <JsxText
                            path="Body2.Copy"
                            params={{
                                EQUIFAX: (
                                    <Link external href={text({ path: 'Body2.Equifax.Target' })}>
                                        <Text path="Body2.Equifax.Text" />
                                    </Link>
                                ),
                                TRANSUNION: (
                                    <Link external href={text({ path: 'Body2.Transunion.Target' })}>
                                        <Text path="Body2.Transunion.Text" />
                                    </Link>
                                ),
                                EXPERIAN: (
                                    <Link external href={text({ path: 'Body2.Experian.Target' })}>
                                        <Text path="Body2.Experian.Text" />
                                    </Link>
                                )
                            }}
                        />
                    </Paragraph>
                </BodySection>
                <CreditBureauDeclineCreditRatingWrapper data-qa-id="credit-rating">
                    <BodySection textAlign="center" data-qa-id="credit-rating-inner">
                        <Heading level="2">
                            <Text path="CreditRating.Heading" />
                        </Heading>
                        <Paragraph textAlign="left">
                            <JsxText
                                path="CreditRating.Body"
                                params={{
                                    CREDIT_MADE_CLEARER_LINK: (
                                        <Link href={text({ path: 'CreditRating.CreditMadeClearer.Target' })}>
                                            <Text path="CreditRating.CreditMadeClearer.Text" />
                                        </Link>
                                    )
                                }}
                            />
                        </Paragraph>
                        <Button
                            as="a"
                            mt="md"
                            href={text({ path: 'CreditRating.Button.Target' })}
                            data-qa-id="credit-rating-tips-button"
                        >
                            <Text path="CreditRating.Button.Text" />
                        </Button>
                    </BodySection>
                </CreditBureauDeclineCreditRatingWrapper>
                <BodySection textAlign="center" data-qa-id="get-in-touch">
                    <Paragraph>
                        <JsxText
                            path="Body3.Copy"
                            params={{
                                GET_IN_TOUCH_LINK: (
                                    <Link href={text({ path: 'Body3.Link.Target' })}>
                                        <Text path="Body3.Link.Text" />
                                    </Link>
                                )
                            }}
                        />
                    </Paragraph>
                </BodySection>
            </Main>
        </>
    );
};

CreditBureauDecline.propTypes = {
    Text: PropTypes.func.isRequired,
    JsxText: PropTypes.func.isRequired
};

const WrappedCreditBureauDecline = withText('CreditBureauDecline')(CreditBureauDecline);

WrappedCreditBureauDecline.displayName = 'CreditBureauDecline';

export default WrappedCreditBureauDecline;
